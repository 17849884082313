import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ReactNode, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Person, PersonOutline, Search } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { observer } from 'mobx-react';
import { useMst } from '../../../models/Root';
import { ILobbyQueueItem, ILobbyStore, LobbyKeys } from '../models/lobby.model';
import { getVideoSnippet } from '../../../services/youtube-api';
import { youtube_v3 } from 'googleapis';
import { IMediaMatch, MediaProviderName } from '../../../services/media-provider.service';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        padding: 10
    }
}));

export interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...rest } = props;

    return (
        <div role='tabpanel' hidden={value != index} id={`tab-panel-${index}`} aria-labelledby={`tab-panel-${index}`} {...rest}>
            {
                value === index && (
                    <Box p={0}>
                        {children}
                    </Box>
                )
            }
        </div>
    )
};

export interface LobbyMemberListItemProps {
    uid: string;
    ready: boolean;
}
const LobbyMemberListItem = ({ uid, ready }: LobbyMemberListItemProps) => {
    return (
        <ListItem>
            <ListItemIcon>
                { ready ? <Person color={'secondary'} /> : <PersonOutline opacity={.6} /> }
            </ListItemIcon>
            <ListItemText primary={uid} secondary={ready ? 'Ready' : 'Connecting...'} />
        </ListItem>
    );
};

export interface QueueListItemProps {
    queueItem: ILobbyQueueItem;
    index: number;
    onPlayNext: (mediaMatch: IMediaMatch) => void;
}
const QueueListItem = ({ queueItem, index, onPlayNext }: QueueListItemProps) => {
    const [videoData, setVideoData] = useState<youtube_v3.Schema$VideoSnippet>();
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        getVideoData();
    }, []);

    const getVideoData = async () => {
        const data = await getVideoSnippet(queueItem[LobbyKeys.videoUrl]);
        if (data) {
            setVideoData(data);
        } else {
            setIsInvalid(true);
        }
    }

    return (
        isInvalid ? null :
        <ListItem button={true} onClick={() => onPlayNext({ providerId: queueItem[LobbyKeys.videoProvider] as MediaProviderName, mediaId: queueItem[LobbyKeys.videoUrl] })}>
            <ListItemText primary={videoData ? videoData.title : 'Loading...'} secondary={videoData ? videoData.channelTitle : null} />
        </ListItem>
    );
};

export interface LobbySidepanelProps {
    onAddToQueue: (videoId: string) => void;
    onPlayNext: (mediaMatch: IMediaMatch) => void;
}

const LobbySidepanel = ({ onAddToQueue, onPlayNext }: LobbySidepanelProps) => {
    const classes = useStyles();
    const store: ILobbyStore = useMst().lobby;

    const [value, setValue] = useState(0);

    const [newVideo, setNewVideo] = useState('');

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleVideoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Validate video ID / URL
        setNewVideo(e.target.value);
    }

    const handleAddVideo = () => {
        onAddToQueue(newVideo);
    }

    const memberList = store.lobbyMembers.map(member => <LobbyMemberListItem key={member.id} uid={member.id} ready={member.ready} />);
    const queueList = store.lobbyQueue.map((queueItem, i) => <QueueListItem key={queueItem.id} queueItem={queueItem} index={i} onPlayNext={onPlayNext} />);

    return (
        <div className={classes.root}>
            <AppBar position={'static'} color={'default'}>
                <Tabs value={value} onChange={handleTabChange} textColor={'secondary'} indicatorColor={'secondary'}>
                    <Tab label={'Queue'} />
                    <Tab label={'Members'} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Paper component={'form'}>
                    <InputBase
                        placeholder={'Add a video to the queue'}
                        onChange={handleVideoInputChange}
                    />
                    <IconButton onClick={handleAddVideo}>
                        <Search />
                    </IconButton>
                </Paper>
                <List classes={{ root: classes.list }} dense={true}>
                    { queueList && queueList.length ? queueList : <Typography>No videos in queue.</Typography> }
                </List>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <List classes={{ root: classes.list }} dense={true}>
                    { memberList && memberList.length ? memberList : <Typography>No members in lobby.</Typography> }
                </List>
            </TabPanel>
        </div>
    );
};

export default observer(LobbySidepanel);
