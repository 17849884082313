import * as React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { AppBar, createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { TimeDisplay } from './LobbyStatus';

import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellularConnectedNoInternet1BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet1Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {PlayerState} from "./YTPlayer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        overflow: 'visible',
        backgroundColor: '#131313'
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        alignContent: 'center'
    },
    liveControls: {
        display: 'flex',
        alignContent: 'center'
    },
    playerControlsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative'
    },
    playButton: {
        padding: 0
    },
    timestampDisplayWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    timestampDisplay: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        opacity: .7
    }
}));

export interface ControlToolbarProps {
    isHost: boolean;
    syncOffset: number;
    playerState: PlayerState;
    playerReady: boolean;

    timestamp: number;
    hostTimestamp: number;
    autoSync: boolean;

    onAutoSyncToggle: () => void;
    onSyncToHost: () => void;
    onPlayToggle?: () => void;
}

const ControlToolbar = ({ isHost, syncOffset, playerState, playerReady, timestamp, hostTimestamp, autoSync, onAutoSyncToggle, onSyncToHost, onPlayToggle }: ControlToolbarProps) => {
    const classes = useStyles();
    let syncIcon = <SignalCellularConnectedNoInternet0BarIcon color={'error'} />;

    if (syncOffset < 2) {
        syncIcon = <SignalCellular4BarIcon color={'secondary'} />;
    } else if (syncOffset < 3) {
        syncIcon = <SignalCellular3BarIcon />;
    } else if (syncOffset < 5) {
        syncIcon = <SignalCellular2BarIcon />;
    } else if (syncOffset < 8) {
        syncIcon = <SignalCellularConnectedNoInternet1BarIcon color={'error'} />;
    }

    return (
        <AppBar position="relative" color="default" className={classes.appBar}>
            <Toolbar variant={'dense'} className={classes.toolbar}>
                <div className={classes.liveControls}>
                    {
                        !isHost ? (
                            <>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={autoSync}
                                            onChange={onAutoSyncToggle}
                                            name="auto-sync"
                                            color="primary"
                                        />
                                    }
                                    label="Auto-sync"
                                />
                                {
                                    autoSync ? null : <Button onClick={onSyncToHost} disabled={syncOffset < 2} startIcon={syncIcon}> Sync</Button>
                                }
                            </>
                        ): null
                    }
                </div>
                <div className={classes.playerControlsWrapper}>

                </div>
                <div className={classes.timestampDisplayWrapper}>
                    <div className={classes.timestampDisplay}>
                        <Typography color={'secondary'} style={{ fontSize: 10, marginRight: 10 }}>HOST</Typography>
                        <TimeDisplay color={'secondary'} fontSize={10} time={hostTimestamp}/>
                    </div>

                    <div className={classes.timestampDisplay}>
                        <Typography color={syncOffset > 8 ? 'error' : 'secondary'} style={{ fontSize: 10, marginRight: 10 }}>LOCAL</Typography>
                        <TimeDisplay color={syncOffset > 8 ? 'error' : 'secondary'} fontSize={10} time={timestamp}/>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default ControlToolbar;
