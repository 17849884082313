import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/navigation/NavigationBar';
import HomePage from './features/home/HomePage';
import firebase from './services/firebase';
import { LoginPage } from './features/auth/Login';
import LobbyResolver from './features/watch/LobbyResolver';
import LobbyCreator from './features/lobby-creator/LobbyCreator';
import { AuthContext } from './features/auth/AuthContext';
import { Provider, rootStore } from './models/Root';

function App() {
    const [ user, setUser ] = useState<firebase.auth.UserCredential | null>(null);

    useEffect(() => {
        firebase.auth().signInAnonymously().then(cred => {
           console.log(cred.user?.uid);
           setUser(cred);
        });
    }, [])

    if (!user || !user.user || !user.user.uid) {
        return <p>Loading...</p>;
    }

    return (
        <AuthContext.Provider value={user.user}>
            <Provider value={rootStore}>
                <Router>
                    <NavigationBar/>
                    <Switch>
                        <Route path={'/create'}>
                            <LobbyCreator />
                        </Route>
                        <Route path={'/watch/:lobbyId'}>
                            <LobbyResolver userId={user.user.uid}/>
                        </Route>
                        <Route path="/login">
                            <LoginPage/>
                        </Route>
                        <Route path="/">
                            <HomePage/>
                        </Route>
                    </Switch>
                </Router>
            </Provider>
        </AuthContext.Provider>
    );
}

export default App;
