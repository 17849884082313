export interface IMediaProvider {
    id: MediaProviderName;
    name: string;
    matcher: RegExp;
    description?: string;
}

export interface IMediaMatch {
    providerId: MediaProviderName;
    mediaId: string;
}

export enum MediaProviderName {
    youtube = 'youtube',
    vimeo = 'vimeo',
    facebook = 'facebook'
}

export type IMediaProviders = {
    [key in MediaProviderName]: IMediaProvider;
};

export const MediaProviders: IMediaProviders = {
    [MediaProviderName.youtube]: {
        id: MediaProviderName.youtube,
        name: 'YouTube',
        matcher: /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*v=(.+)/
    },
    [MediaProviderName.vimeo]: {
        id: MediaProviderName.vimeo,
        name: 'Vimeo',
        matcher: /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/,
    },
    [MediaProviderName.facebook]: {
        id: MediaProviderName.facebook,
        name: 'Facebook',
        matcher: /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:\d+)\/videos\/(\d+)/
    }
}

export class MediaProviderService {
    public static MediaProviders = MediaProviders;

    public static getProviderFromId(providerId: string): IMediaProvider | null {
        if (MediaProviderService.MediaProviders[providerId as MediaProviderName]) {
            return MediaProviderService.MediaProviders[providerId as MediaProviderName];
        }
        return null;
    }

    public static matchProvider(url: string): IMediaMatch | null {
        for (const provider in MediaProviderService.MediaProviders) {
            if (MediaProviderService.MediaProviders.hasOwnProperty(provider)) {
                const matches = url.match(MediaProviderService.MediaProviders[provider as MediaProviderName].matcher);
                if (matches && matches[1]) {
                    return {
                        providerId: MediaProviderService.MediaProviders[provider as MediaProviderName].id,
                        mediaId: matches[1]
                    };
                }
            }
        }
        return null;
    }
}
