import { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import ReactPlayer from "react-player";
import VideoControls from './VideoControls';

export enum PlayerState {
    unstarted = -1,
    ended = 0,
    playing = 1,
    paused = 2,
    buffering = 3,
    videoCued = 5
}

export interface PlayerStateChange {
    timestamp: number;
    state: PlayerState;
}

export interface YTPlayerProps {
    videoId: string | null;
    playerState: PlayerState;
    onReady: (videoData: VideoData) => void;
    onStateChange: (playerState: PlayerState) => void;
    onTimestampUpdate: (time: number) => void;
    onNext: () => void;
    timestamp: number;
    width?: string;
    height?: string;
    mute: 0 | 1 | undefined;
    isHost: boolean;
    startTimestamp: number;
    syncTimestamp: number;
}

export interface VideoData {
    author: string;
    title: string;
    video_id: string;
}

const YTPlayer = ({ videoId, playerState, onReady, onStateChange, onTimestampUpdate, isHost = false, timestamp, startTimestamp = 0, syncTimestamp = 0, onNext }: YTPlayerProps) => {
    const [player, setPlayer] = useState();
    const playerRef: MutableRefObject<any> = useRef();

    // const [playerState, setPlayerState] = useState<PlayerState>(-1);
    const [duration, setDuration] = useState(0);

    const [volume, setVolume] = useState(.5);
    const [lastVolume, setLastVolume] = useState(.5);
    const [changingVolume, setChangingVolume] = useState(false);

    useEffect(() => {
        if (syncTimestamp && !isHost && player) {
            console.info(`sync timestamp updated, running seekTo to ${syncTimestamp}`);
            player.seekTo(syncTimestamp);
        }
    }, [syncTimestamp, player]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (player && player.getPlayerState() !== playerState) {
                if (playerState === PlayerState.playing) {
                    player.playVideo();
                } else if (player && playerState === PlayerState.paused) {
                    player.pauseVideo();
                }
            }
        }, 200);
        return () => clearInterval(interval);
    }, [playerState, player])

    function _onReady() {
        setTimeout(() => {
            const newInstance = playerRef.current.getInternalPlayer();
            if (newInstance) {
                setPlayer(newInstance);
                onReady(newInstance.getVideoData() as VideoData);
            }
        }, 1000);
    }

    function _onStart() {
        console.debug(`on start`);
        onStateChange(PlayerState.playing);
    }

    function _onPlay() {
        console.debug(`on play`);
    }

    function _onBuffer() {
        console.debug(`on buffer`);
    }

    function _onBufferEnd() {
        console.debug(`on buffer end`);
    }

    function _onPause() {
        console.debug(`on pause`);
    }

    function _onEnded() {
        console.debug(`on ended`);
        onNext();
    }

    function _onDuration(seconds: number) {
        console.debug(`on duration`);
        setDuration(seconds);
    }

    function _onProgress(state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) {
        onTimestampUpdate(state.playedSeconds);
    }

    const togglePlay = () => {
        if (playerState == PlayerState.playing) {
            onStateChange(PlayerState.paused);
        } else if (playerState === PlayerState.paused || playerState === PlayerState.unstarted) {
            onStateChange(PlayerState.playing);
        }
    };

    /** Section start -- VOLUME HELPERS **/
    const toggleMute = () => {
        if (volume === 0) {
            setVolume(lastVolume);
        } else {
            setLastVolume(volume);
            setVolume(0);
        }
    };

    const changeVolume = (e: React.ChangeEvent<{}>, newVolume: number | number[]) => {
        if (Array.isArray(newVolume)) {
            newVolume = newVolume[0];
        }

        if (!changingVolume) {
            setLastVolume(volume);
        }

        setVolume(newVolume);
        setChangingVolume(true);
    }

    const changeVolumeCommited = (e: React.ChangeEvent<{}>, newVolume: number | number[]) => {
        if (Array.isArray(newVolume)) {
            newVolume = newVolume[0];
        }
        setVolume(newVolume);
        setChangingVolume(false);
    };
    /** Section end -- VOLUME HELPERS **/

    const seekTo = (e: React.ChangeEvent<{}>, newTimestamp: number | number[]) => {
        if (Array.isArray(newTimestamp)) {
            newTimestamp = newTimestamp[0];
        }
        player.seekTo(newTimestamp);
    }

    return videoId ?
        <VideoControls
            onPlayToggle={togglePlay}
            onMuteToggle={toggleMute}
            onVolumeChange={changeVolume}
            onVolumeChangeCommited={changeVolumeCommited}
            onSeekTo={seekTo}
            state={playerState}
            volume={volume}
            ready={!!player}
            duration={duration}
            played={timestamp}
        >
            <ReactPlayer
                volume={volume}
                playing={playerState === PlayerState.playing}
                width="100%"
                height="100%"
                controls={false}
                ref={playerRef}
                config={{
                    youtube: {playerVars: { start: 0, autoplay: 0 }}
                }}
                url={`https://www.youtube.com/watch?v=${videoId}`}
                progressInterval={500}
                onReady={_onReady}
                onPlay={_onPlay}
                onPause={_onPause}
                onBuffer={_onBuffer}
                onBufferEnd={_onBufferEnd}
                onProgress={_onProgress}
                onStart={_onStart}
                onDuration={_onDuration}
                onEnded={_onEnded}
            />
        </VideoControls>
        :
        <p>No video ID provided.</p>;
}

export default YTPlayer;
