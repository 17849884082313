import axios from 'axios';
import { youtube_v3 } from 'googleapis';

export async function getVideoSnippet(videoId: string): Promise<youtube_v3.Schema$VideoSnippet | undefined> {
    try {
        const response = await axios.get<youtube_v3.Schema$VideoListResponse>(`${process.env.REACT_APP_YOUTUBE_API_BASE_URL}/videos`, {
            params: {
                part: 'snippet',
                id: videoId,
                key: process.env.REACT_APP_YOUTUBE_API_KEY
            }
        });
        if (response.data?.items && response.data.items[0]) {
            return response.data.items[0].snippet;
        }
    } catch (e) {
        console.error(e);
    }
}

