import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';
import clsx from 'clsx';
import './WatchPage.css';
import InteractionDialog from './components/InteractionDialog';
import LobbyStatus from './components/LobbyStatus';
import YTPlayer, {PlayerState, VideoData} from './components/YTPlayer';
import useDocumentTitle from '../../services/document-title';
import ControlToolbar from './components/ControlToolbar';
import {createStyles, Drawer, Theme} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LobbySidepanel from './components/Sidepanel';
import { observer } from 'mobx-react';
import { Lobby } from './services/lobby-control';
import { useMst } from '../../models/Root';
import { IMediaMatch } from '../../services/media-provider.service';

export interface LobbyPageProps {
    userId: string;
    lobby: Lobby;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        marginRight: -350,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        marginRight: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: 350,
        flexShrink: 0
    },
    drawerPaper: {
        width: 350,
        position: 'relative'
    }
}));

export const LobbyPage = observer(({ userId, lobby }: LobbyPageProps) => {
    const classes = useStyles();
    const store = useMst().lobby;

    const [playerReady, setPlayerReady] = useState(false);
    const [interactionReady, setInteractionReady] = useState<boolean>(false);
    const [timestamp, setTimestamp] = useState(0);
    const [syncTimestamp, setSyncTimestamp] = useState(0);

    const [syncOffset, setSyncOffset] = useState(-1);
    const [title, setTitle] = useState('Loading lobby');

    const [autoSync, setAutoSync] = useState(true);

    const [sidePanelOpen, setSidePanelOpen] = useState(false);

    useDocumentTitle(title);

    useEffect(() => {
        console.debug(`[DEBUG] Readiness state updated.`);
        if (playerReady && interactionReady) {
            console.debug(`[DEBUG] Ready to start.`);
            lobby.setUserReady(true);
        }
    }, [playerReady, interactionReady]);

    useEffect(() => {
        if (autoSync && userId !== store.host && syncOffset > 8) {
            setSyncTimestamp(store.timestamp);
        }
    }, [autoSync, syncOffset]);

    const handlePlayerStateChange = (state: PlayerState) => {
        console.debug(`state change from UI: ${state}`);
        if (state === PlayerState.playing) {
            playRequest();
        } else if (state === PlayerState.paused) {
            pauseRequest();
        }
    }

    function playRequest() {
        lobby.play();
    }

    function pauseRequest() {
        lobby.pause();
    }

    const handleOnPlayerReady = (videoData: VideoData) => {
        console.log('player ready');
        setPlayerReady(true);
        setTitle(videoData.title);
    }

    const handleInteractionDialogClose = () => {
        console.log('interaction ready');
        setInteractionReady(true);
    }

    const handleTimestampUpdate = (timestamp: number) => {
        if (userId === store.host) {
            lobby.setTimestamp(timestamp);
        }
        setTimestamp(timestamp);
    }

    const handleSyncToHost = () => {
        if (userId !== store.host) {
            setSyncTimestamp(store.timestamp);
        }
    }

    const handleAutoSyncToggle = () => {
        setAutoSync(!autoSync);
    }

    const handleSidePanelToggle = () => {
        setSidePanelOpen(!sidePanelOpen);
    }

    const handleAddToQueue = async (videoUrl: string) => {
        await lobby.addToQueue(videoUrl);
    }

    const handleNextInQueue = async () => {
        if (userId === store.host) {
            await lobby.playNextInQueue();
        }
    }

    const handlePlayNext = async (mediaMatch: IMediaMatch) => {
        if (mediaMatch && mediaMatch.mediaId && mediaMatch.providerId) {
            await lobby.playNow(mediaMatch.providerId, mediaMatch.mediaId);
        }
    }

    useEffect(() => {
        setSyncOffset(Math.abs(timestamp - store.timestamp));
    }, [timestamp]);

    const containerStyles = { display: 'grid', gridTemplateRows: 'auto 1fr auto' };
    const wrapperStyles: CSSProperties = { display: 'flex', justifyContent: 'center', width: '100%', height: '100%' };

    return (
        <>
            <InteractionDialog open={!interactionReady} onClose={handleInteractionDialogClose} />
            <div className={classes.root}>
                <div style={containerStyles} className={clsx(classes.content, { [classes.contentShift]: sidePanelOpen })}>
                    <LobbyStatus playerReady={playerReady} onSidePanelToggle={handleSidePanelToggle}/>
                    <div style={wrapperStyles}>
                        <YTPlayer
                            width="100%" height="100%"
                            videoId={store.videoId} mute={0} playerState={store.state}
                            onReady={handleOnPlayerReady} onStateChange={handlePlayerStateChange}
                            startTimestamp={store.timestamp} isHost={userId === store.host} onTimestampUpdate={handleTimestampUpdate}
                            syncTimestamp={syncTimestamp} timestamp={timestamp} onNext={handleNextInQueue}
                        />
                    </div>
                    <ControlToolbar
                        isHost={userId === store.host} playerReady={playerReady} syncOffset={syncOffset}
                        playerState={store.state} timestamp={timestamp} hostTimestamp={store.timestamp} autoSync={autoSync}
                        onAutoSyncToggle={handleAutoSyncToggle} onSyncToHost={handleSyncToHost}
                    />
                </div>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    open={sidePanelOpen}
                >
                    <LobbySidepanel onAddToQueue={handleAddToQueue} onPlayNext={handlePlayNext} />
                </Drawer>
            </div>
        </>
    );
});

export default LobbyPage;
