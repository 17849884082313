import * as React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from "@material-ui/core/IconButton";
import { Pause, PlayArrow, Settings, VolumeDown, VolumeOff, VolumeUp } from "@material-ui/icons";
import { PlayerState } from './YTPlayer';
import Slider from '@material-ui/core/Slider';
import { useEffect } from 'react';
import { TimeDisplay } from './LobbyStatus';
import withStyles from '@material-ui/core/styles/withStyles';


const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    controlsContainer: {
        padding: '4px 15px',
        display: 'grid',
        gridTemplateColumns: 'auto auto 1fr auto',
        gridColumnGap: 10,
        alignItems: 'center'
    },
    volumeControlsContainer: {
        display: 'flex'
    },
    volumeSlider: {
        margin: '0 10px'
    },
    progressControls: {
        display: 'flex'
    },
    progressTimeDisplay: {
        display: 'flex'
    }
}));

const VolumeIcon = ({ volume }: { volume: number }) => {
    if (volume > .5) {
        return <VolumeUp />;
    } else if (volume > 0) {
        return <VolumeDown />;
    } else {
        return <VolumeOff />
    }
}

export interface VideoControlsProps {
    children: any;
    ready: boolean;

    state: PlayerState;
    volume: number;
    duration: number;
    played: number;

    onPlayToggle: () => void;
    onMuteToggle?: () => void;
    onVolumeChange?: (event: React.ChangeEvent<{}>, volume: number | number[]) => void;
    onVolumeChangeCommited?: (event: React.ChangeEvent<{}>, volume: number | number[]) => void;
    onSeekTo?: (event: React.ChangeEvent<{}>, volume: number | number[]) => void;
}

const VolumeSlider = withStyles({
    thumb: {
        '&:active': {
            boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)'
        }
    }
})(Slider);

const ProgressSlider = withStyles({
    root: {
        height: 6
    },
    track: {
        height: 6
    },
    rail: {
        height: 6
    },
    thumb: {
        color: 'transparent',
        marginTop: -3
    }
})(Slider);

const VideoControls = ({ children, ready, state, volume, duration, played, onPlayToggle, onMuteToggle, onVolumeChange, onVolumeChangeCommited, onSeekTo }: VideoControlsProps) => {
    const classes = useStyles();

    useEffect(() => {
        console.debug(`[DEBUG] volume change to ${volume}`);
    }, [volume])

    return (
        <div className={classes.wrapper}>
            <div className={'youtubeContainer'}>
                { children }
            </div>
            {
                ready ? (
                    <div className={classes.controlsContainer}>
                        <div>
                            <IconButton size={'small'} onClick={onPlayToggle}>
                                { state === PlayerState.playing ? <Pause /> : <PlayArrow /> }
                            </IconButton>
                        </div>
                        <div className={classes.volumeControlsContainer}>
                            <IconButton size={'small'} onClick={onMuteToggle}>
                                <VolumeIcon volume={volume} />
                            </IconButton>
                            <VolumeSlider className={classes.volumeSlider} style={{ width: 100, marginLeft: 10 }}
                                    value={volume} min={0} max={1} step={0.001}
                                    onChange={onVolumeChange} onChangeCommitted={onVolumeChangeCommited} />
                        </div>
                        <div className={classes.progressControls}>
                            <div className={classes.progressTimeDisplay}>
                                <TimeDisplay time={played} /> / <TimeDisplay time={duration} />
                            </div>
                            <ProgressSlider
                                style={{ width: '100%' }}
                                value={played} min={0} max={duration} step={0.005}
                                onChangeCommitted={onSeekTo}
                            />
                        </div>
                        <div>
                            <IconButton size={'small'}>
                                <Settings />
                            </IconButton>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
};

export default VideoControls;
