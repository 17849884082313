import { cast, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { IMediaMatch, MediaProviderName } from '../../../services/media-provider.service';

export enum LobbyKeys {
    state = 'state',
    host = 'host',
    videoUrl = 'videoId',
    videoProvider = 'videoProvider',
    timestamp = 'hostTimestamp'
}

export enum PlayerState {
    unstarted = -1,
    ended = 0,
    playing = 1,
    paused = 2,
    buffering = 3,
    videoCued = 5
}

export const playerStateList = [PlayerState.unstarted, PlayerState.ended, PlayerState.playing, PlayerState.paused, PlayerState.buffering, PlayerState.videoCued];

export const LobbyMember = types
    .model({
        id: types.identifier,
        nickname: types.optional(types.string, ''),
        ready: types.boolean
    })
    .actions(self => ({
        setReady(isReady: boolean) {
            self.ready = isReady;
        },
        setNickname(nickname: string) {
            self.nickname = nickname;
        }
    }));
export interface ILobbyMember extends Instance<typeof LobbyMember> {}
export interface ILobbyMemberSnapshotIn extends SnapshotIn<typeof LobbyMember> {}
export interface ILobbyMemberSnapshotOut extends SnapshotOut<typeof LobbyMember> {}

export const LobbyQueueItem = types
    .model({
        id: types.identifier,
        [LobbyKeys.videoUrl]: types.string,
        [LobbyKeys.videoProvider]: types.string,
        position: types.number
    })
    .actions(self => ({
        setPosition(position: number) {
            self.position = position
        },
        nextPosition() {
            if (self.position > 0) {
                self.position--;
            }
        },
        previousPosition() {
            self.position++
        }
    }));
export interface ILobbyQueueItem extends Instance<typeof LobbyQueueItem> {}
export interface ILobbyQueueItemSnapshotIn extends SnapshotIn<typeof LobbyQueueItem> {}
export interface ILobbyQueueItemSnapshotOut extends SnapshotOut<typeof LobbyQueueItem> {}

export const LobbyStore = types
    .model({
        id: 'placeholder-id',
        url: types.maybeNull(types.string),
        provider: types.maybeNull(types.string),
        host: types.maybeNull(types.string),
        timestamp: 0,
        state: types.optional(types.number, PlayerState.unstarted),
        lobbyMembers: types.optional(types.array(LobbyMember), []),
        lobbyQueue: types.optional(types.array(LobbyQueueItem), []),
        autoSync: true,
        localTimestamp: 0
    })
    .views(self => ({
        memberStatusCount(): { total: number, ready: number } {
            return {
                total: self.lobbyMembers.length,
                ready: self.lobbyMembers.filter(m => m.ready).length
            }
        },
        get videoId(): string | null {
            return self.url;
        },
        get videoProvider(): string | null {
            return self.provider;
        }
    }))
    .actions(self => ({
        setHost(newHost: string) {
            self.host = newHost;
        },
        setVideoUrl(url: string) {
            self.url = url;
        },
        setProvider(provider: MediaProviderName) {
            self.provider = provider;
        },
        setTimestamp(newTimestamp: number) {
            self.timestamp = newTimestamp;
        },
        play() {
            self.state = PlayerState.playing;
        },
        pause() {
            self.state = PlayerState.paused;
        },
        toggleState() {
            self.state = self.state === PlayerState.playing ? PlayerState.paused : PlayerState.playing;
        },
        setState(state: PlayerState) {
            console.debug(`[DEBUG] LobbyStore setState(${state})`);
            self.state = state;
        },
        addMember(id: string, ready: boolean, nickname?: string) {
            self.lobbyMembers.push({ id, ready, nickname });
        },
        removeMember(member: ILobbyMember) {
            self.lobbyMembers.remove(member);
        },
        updateMemberStatus(id: string, ready: boolean) {},
        setMembers(members: { [key: string]: boolean }) {
            const memberArray = [];
            for (let key in members) {
                if (members.hasOwnProperty(key)) {
                    memberArray.push({ id: key, ready: members[key] });
                    self.lobbyMembers = cast(memberArray);
                }
            }
        },
        addToQueue(id: string, url: string, provider: MediaProviderName) {
            self.lobbyQueue.push({
                id,
                [LobbyKeys.videoUrl]: url,
                [LobbyKeys.videoProvider]: provider,
                position: self.lobbyQueue.length
            });
        },
        removeFromQueueById(id: string) {
            const newQueue = self.lobbyQueue
                .filter(item => item.id === id)
                .sort((prev, next) => prev.position - next.position)
                .map((item, index) => ({ ...item, position: index }));
            self.lobbyQueue.replace(newQueue);
        },
        setQueue(queue: { [key: string]: { [LobbyKeys.videoUrl]: string, [LobbyKeys.videoProvider]: MediaProviderName, position: number } }) {
            const newQueue = [];
            for (let key in queue) {
                if (queue.hasOwnProperty(key)) {
                    const item = {
                        id: key,
                        [LobbyKeys.videoUrl]: queue[key][LobbyKeys.videoUrl],
                        [LobbyKeys.videoProvider]: queue[key][LobbyKeys.videoProvider],
                        position: queue[key].position
                    };
                    newQueue.push(LobbyQueueItem.create(item));
                }
            }
            self.lobbyQueue.replace(newQueue.sort((prev, next) => prev.position - next.position))
        },
        playNext(video?: IMediaMatch) {
            if (video) {
                this.setVideoUrl(video?.mediaId);
                this.setProvider(video?.providerId);
            } else {
                this.setVideoUrl(self.lobbyQueue[0][LobbyKeys.videoUrl]);
                self.lobbyQueue.unshift();
            }
        }
    }));
export interface ILobbyStore extends Instance<typeof LobbyStore> {}
export interface ILobbyStoreSnapshotIn extends SnapshotIn<typeof LobbyStore> {}
export interface ILobbyStoreSnapshotOut extends SnapshotOut<typeof LobbyStore> {}
