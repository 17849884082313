import * as React from 'react'
import { Facebook, Videocam, YouTube } from '@material-ui/icons';
import { MediaProviderName } from '../services/media-provider.service';

export type IMediaProviderIcons = {
    [key in MediaProviderName]: any;
};

export const MediaProviderIcons: IMediaProviderIcons = {
    [MediaProviderName.youtube]: <YouTube style={{ color: '#FF0000' }} />,
    [MediaProviderName.facebook]: <Facebook style={{ color: '#4267B2' }} />,
    [MediaProviderName.vimeo]: <Videocam style={{ color: '#86c9ef' }} />
};

const MediaProviderIcon = ({ providerId }: { providerId: MediaProviderName }) => {
    return MediaProviderIcons[providerId];
}
export default MediaProviderIcon;
