import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, fade, IconButton, Theme } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import MediaProviderIcon from '../MediaProviderIcon';
import Button from '@material-ui/core/Button';
import { IMediaMatch, MediaProviderService } from '../../services/media-provider.service';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        home: {
            userSelect: 'none',
            cursor: 'pointer'
        },
        appBar: {
            backgroundColor: '#303030'
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderLeftTopRadius: theme.shape.borderRadius,
            borderLeftBottomRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.black, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.black, 0.25),
            },
            marginRight: 0,
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchButton: {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            backgroundColor: fade(theme.palette.common.white, 0.1),
            '&:disabled': {
                backgroundColor: fade(theme.palette.common.white, 0.05)
            }
        },
        prefixIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        postfixIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            right: 0,
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '400px',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

const fullUrlMatcher = /.*youtube.com\/watch.*v=(.+)/i;

const NavigationBar = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [mediaMatch, setMediaMatch] = useState<IMediaMatch | null>(null);

    const classes = useStyles();
    const history = useHistory();

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVideoUrl(event.target.value);
        const match = MediaProviderService.matchProvider(event.target.value);
        if (match) {
            setMediaMatch(match);
        } else {
            setMediaMatch(null);
        }
    }

    const handleSubmitNavigate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        navigate();
    }

    const handleNavigate = (_: React.MouseEvent<HTMLButtonElement>) => {
        navigate();
    }

    const navigate = () => {
        const matches = videoUrl.match(fullUrlMatcher);
        if (matches && matches[1].length > 0) {
            history.push(`/create?v=${videoUrl}`);
        }
    }

    return (<AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense">
            <div onClick={() => history.push('/')} className={classes.home}>
                <Typography variant={'h5'}>Syncr</Typography>
            </div>
            <div className={ classes.search }>
                <div className={ classes.prefixIcon }>
                    { mediaMatch && mediaMatch.providerId ?
                        <MediaProviderIcon providerId={ mediaMatch.providerId }/> : <SearchIcon/>
                    }
                </div>
                <form onSubmit={handleSubmitNavigate}>
                    <InputBase
                        onChange={handleUrlChange}
                        placeholder="Type a video URL"
                        classes={ {
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        } }
                        inputProps={ {'aria-label': 'type a YouTube video URL or ID'} }
                    />
                </form>
                {/*<div className={ classes.postfixIcon }>*/}
                {/*    <IconButton onClick={handleNavigate} aria-label={'create a lobby for the given video URL or ID'}>*/}
                {/*        <YoutubeIcon />*/}
                {/*    </IconButton>*/}
                {/*</div>*/}
            </div>
            <Button className={classes.searchButton} onClick={handleNavigate} disabled={!mediaMatch}>
                GO
            </Button>
        </Toolbar>
    </AppBar>);
}

export default NavigationBar;
