import * as React from 'react';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export interface InteractionDialogProps {
    open: boolean;
    onClose: () => void;
}
const InteractionDialog = ({ open, onClose }: InteractionDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogActions>
                <Button color={'secondary'} onClick={onClose}>I'M READY BOYS</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InteractionDialog;
