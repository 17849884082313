import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from '../../services/firebase';

export const LoginPage: React.FC = () => {
    const history = useHistory(),
        location = useLocation();

    const { from }: any = location.state || { from: { pathname: '/' }};
    const login = () => {
        firebase.auth().signInAnonymously().then(() => history.replace(from));
    }

    return (
        <div>
            <p>You myst log in to view the page at {from.pathname}</p>
            <button onClick={login}>Log in</button>
        </div>
    )
}
