import * as React from 'react';
import { useEffect, useState } from 'react';
import { Lobby } from './services/lobby-control';
import { LobbyPage } from './Lobby';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useMst } from '../../models/Root';
import { PlayerState } from './models/lobby.model';

const LobbyContainer = ({ userId, lobbyId }: { userId: string, lobbyId: string }) => {
    const [lobby, setLobby] = useState<Lobby>(new Lobby(lobbyId, userId));

    const store = useMst();

    // Alerts
    const [newUserAlert, setNewUserAlert] = useState(false);
    const [userLeftAlert, setUserLeftAlert] = useState(false);
    const [userReadyAlert, setUserReadyAlert] = useState(false);
    const [youAreTheCaptainNowAlert, setYouAreTheCaptainNowAlert] = useState(false);

    useEffect(() => {
        store.setLobbyId(lobbyId);
        lobby.join();

        return () => {
            lobby.leave();
        };
    }, []);

    useEffect(() => {
        lobby.queue.on('value', snapshot => {
            console.debug(`firebase: queue updated`);
            console.log(snapshot.val());
            store.lobby.setQueue(snapshot.val());
        });

        lobby.state.on('value', snapshot => {
            const playbackState: PlayerState = snapshot.val();
            if (playbackState !== store.lobby.state) {
                store.lobby.setState(playbackState);
            }
        });

        lobby.videoUrl.on('value', snapshot => {
            store.lobby.setVideoUrl(snapshot.val());
        });

        lobby.host.on('value', snapshot => {
            if (store.lobby.host !== userId && snapshot.val() === userId) {
                setYouAreTheCaptainNowAlert(true);
            }
            store.lobby.setHost(snapshot.val());
        });

        lobby.timestamp.on('value', snapshot => {
            store.lobby.setTimestamp(snapshot.val());
        });

        lobby.members.on('value', snapshot => {
            console.debug(`firebase: lobby members on value`);
            store.lobby.setMembers(snapshot.val());
        });

        lobby.members.on('child_added', member => {
            console.debug(`firebase: members child added`);
            if (member.key !== userId) {
                setNewUserAlert(true);
            }
        });

        lobby.members.on('child_changed', member => {
            console.debug(`firebase: members child changed`);
            if (member.key !== userId && member.val()) {
                setUserReadyAlert(true);
            }
        });

        lobby.members.on('child_removed', member => {
            console.debug(`firebase: members child removed`);
            if (member.key !== userId) {
                setUserLeftAlert(true);
            }
        });

        return () => {
            lobby.queue.off();
            lobby.members.off();
            lobby.state.off();
            lobby.timestamp.off();
            lobby.host.off();
            lobby.videoUrl.off();
        }
    }, []);

    return (
        <>
            <LobbyPage userId={userId} lobby={lobby} />
            <Snackbar open={newUserAlert} autoHideDuration={2000} onClose={() => setNewUserAlert(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={'info'}>New user joined the lobby.</Alert>
            </Snackbar>
            <Snackbar open={userReadyAlert} autoHideDuration={2000} onClose={() => setUserReadyAlert(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={'success'}>Someone is ready :)</Alert>
            </Snackbar>
            <Snackbar open={userLeftAlert} autoHideDuration={2000} onClose={() => setUserLeftAlert(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={'info'}>User left the lobby.</Alert>
            </Snackbar>
            <Snackbar open={youAreTheCaptainNowAlert} autoHideDuration={2000} onClose={() => setYouAreTheCaptainNowAlert(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={'info'}>You are the captain now.</Alert>
            </Snackbar>
        </>
    );
}

export default LobbyContainer;
