import * as firebase from 'firebase';
import { db } from '../../../services/firebase';
import { ILobbyQueueItem, LobbyKeys, PlayerState } from '../models/lobby.model';
import { MediaProviderName, MediaProviderService } from '../../../services/media-provider.service';

export class Lobby {
    public lobbies: firebase.database.Reference = db.ref('/lobbies');
    public lobbyMembers: firebase.database.Reference = db.ref('/lobbyMembers');
    public lobbyQueues: firebase.database.Reference = db.ref('/lobbyQueues');

    public lobby: firebase.database.Reference;
    public members: firebase.database.Reference;
    public queue: firebase.database.Reference;
    public user: firebase.database.Reference;

    constructor(public lobbyId: string, public userId: string) {
        this.lobby = this.lobbies.child(lobbyId);
        this.members = this.lobbyMembers.child(lobbyId);
        this.queue = this.lobbyQueues.child(lobbyId);
        this.user = this.members.child(userId);
        this.user.onDisconnect().remove();
    }

    /**
     * Lobby information getters.
     */

    public get state() {
        return this.lobby.child(LobbyKeys.state);
    }

    public get host() {
        return this.lobby.child(LobbyKeys.host);
    }

    public get videoUrl() {
        return this.lobby.child(LobbyKeys.videoUrl);
    }

    public get videoProvider() {
        return this.lobby.child(LobbyKeys.videoProvider);
    }

    public get timestamp() {
        return this.lobby.child(LobbyKeys.timestamp);
    }


    public async setTimestamp(timestamp: number) {
        return await this.lobby.child(LobbyKeys.timestamp).set(timestamp);
    }


    /**
     * Queue and playback controls.
     */

    public async setState(state: PlayerState) {
        console.debug(`LobbyControl setState(${state})`);
        return await this.state.set(state);
    }

    public async play() {
        return await this.setState(PlayerState.playing);
    }

    public async pause() {
        return await this.setState(PlayerState.paused);
    }

    public async playNow(provider: MediaProviderName, url: string) {
        try {
            await this.lobby.update({
                [LobbyKeys.videoUrl]: url,
                [LobbyKeys.videoProvider]: provider,
                [LobbyKeys.timestamp]: 0
            });
            await this.removeFromQueue(url);
        } catch (e) {
            console.error(e);
        }
    }

    public async playNextInQueue() {
        try {
            const nextInQueueSnapshot = await this.queue
                .once('value');

            const ordered: ILobbyQueueItem[] = [];
            nextInQueueSnapshot.forEach(item => {
                ordered.push({
                    ...item.val(),
                    id: item.key
                });
            });
            const next = ordered.sort((prev, next) => prev.position - next.position)[0];

            if (next && next.id) {
                await this.queue.child(next.id).remove();
                await this.lobby.update({
                    [LobbyKeys.videoUrl]: next[LobbyKeys.videoUrl],
                    [LobbyKeys.videoProvider]: next[LobbyKeys.videoProvider],
                    [LobbyKeys.timestamp]: 0
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async addToQueue(fullUrl: string) {
        const mediaMatch = MediaProviderService.matchProvider(fullUrl);
        if (mediaMatch && mediaMatch.providerId && mediaMatch.mediaId) {
            try {
                const snapshot = await this.queue.once('value');
                await this.queue.push({
                    [LobbyKeys.videoUrl]: mediaMatch.mediaId,
                    [LobbyKeys.videoProvider]: mediaMatch.providerId,
                    position: snapshot.numChildren()
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            console.warn(`invalid video URL provider ${fullUrl}`);
        }
    }

    public async removeFromQueue(videoUrl: string) {
        try {
            const queue = await this.queue.once('value');
            let toRemove: string | null = null;
            queue.forEach(queueItem => {
                if (queueItem.val()[LobbyKeys.videoUrl] === videoUrl && !toRemove) {
                    toRemove = queueItem.key;
                }
            });
            if (toRemove) {
                await this.queue.child(toRemove).remove();
            }
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Member controls.
     */

    public async setUserReady(ready: boolean) {
        return await this.user.set(ready);
    }

    public async join(ready: boolean = false) {
        console.debug(`[DEBUG][${this.userId}] Joining lobby with ID ${this.lobby.key}`);
        try {
            await this.members.child(this.userId).set(ready);
        } catch (e) {
            console.error(`[ERROR] Error joining lobby.`);
            return e;
        }
    }

    public async leave() {
        console.debug(`[DEBUG][${ this.userId }] Leaving lobby with ID ${ this.lobby.key }`);
        try {
            await this.user.remove();
        } catch (e) {
            console.error(`[ERROR] Error leaving lobby.`);
            return e;
        }
    }


}
