import { useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import * as React from 'react';
import { AuthContext } from '../auth/AuthContext';
import { LobbyService } from '../watch/services/lobby.service';
import { MediaProviderService } from '../../services/media-provider.service';
import { useQuery } from '../../services/url.helpers';

const LobbyCreator = () => {
    const user = useContext(AuthContext);
    const [ready, setReady] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>();

    const location = useLocation();
    const query = useQuery(),
        initialVideoUrl = query.get('v');

    const [videoUrl, setVideoUrl] = useState(initialVideoUrl);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const v = query.get('v');
        if (v) {
            setVideoUrl(v);
        }
    }, [location]);

    const init = async () => {
        console.debug(`[DEBUG] Entering lobby creator.`);
        if (videoUrl && user && user.uid) {
            console.debug(`[DEBUG] Video URL provided (${videoUrl}), creating new lobby.`);
            const match = MediaProviderService.matchProvider(videoUrl);
            if (match && match.providerId && match.mediaId) {
                const lobby = await LobbyService.create(user.uid, match);
                if (lobby && lobby.key) {
                    console.debug(`[DEBUG] New lobby created (${ lobby.key }), redirecting to new lobby URL.`);
                    setRedirectUrl(`/watch/${ lobby.key }`)
                } else {
                    console.debug(`[DEBUG] Error creating new lobby, redirecting to '/'.`);
                    setRedirectUrl('/');
                }
            } else {
                console.debug(`[DEBUG] Invalid video URL provided. No video provider match.`);
            }
        } else {
            console.debug(`[DEBUG] No video or lobby ID was provided, redirecting to '/'.`);
            setRedirectUrl('/');
        }
        setReady(true);
    }

    useEffect(() => {
        init();
    }, [videoUrl]);

    if (!ready) {
        return <p>Loading...</p>
    }

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    } else {
        return <Redirect to={'/'} />;
    }
}

export default LobbyCreator;
