import { useRef, useEffect } from 'react';

export const defaultTitleSuffix = `- Watch Sync`;

export default function useDocumentTitle(title: string, avoidSuffix: boolean = false, retainOnUnmount: boolean = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = `${title} ${defaultTitleSuffix} `;
    }, [title])

    useEffect(() => {
        const defaultPageTitle = defaultTitle.current;
        return () => {
            if (!retainOnUnmount) {
                document.title = defaultPageTitle;
            }
        }
    }, [retainOnUnmount]);
}
