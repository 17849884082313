import { LobbyStore } from '../features/watch/models/lobby.model';
import { Instance, onSnapshot, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

const RootModel = types
    .model({
        lobby: LobbyStore
    })
    .actions(self => ({
        setLobbyId(id: string) {
            console.debug(`rootStore: setLobbyId`);
            self.lobby.id = id;
        }
    }))

export const rootStore = RootModel.create({
    lobby: LobbyStore.create({})
});

// onSnapshot(rootStore, snapshot => console.log(snapshot));

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;
export function useMst() {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error(`nullish RootStore, please add a context provider`);
    }
    return store;
}
