import { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import * as React from 'react';
import LobbyContainer from './LobbyContainer';
import { LobbyService } from './services/lobby.service';

export interface WatchPageResolverProps {
    userId: string;
}

const LobbyResolver = ({ userId }: WatchPageResolverProps) => {
    const [ready, setReady] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const [actualLobbyId, setActualLobbyId] = useState<string>();

    const { lobbyId } = useParams<{ lobbyId: string }>();

    const init = async () => {
        if (lobbyId) {
            console.debug(`[DEBUG] Lobby ID provided (${lobbyId}), checking availability.`);
            // IF EXISTS - JOIN
            if (await LobbyService.exists(lobbyId)) {
                console.debug(`[DEBUG] Lobby ${lobbyId} exists, setting lobby instance connection.`);
                setActualLobbyId(lobbyId);
            } else {
                console.debug(`[DEBUG] Lobby ${lobbyId} does not exist, redirecting to '/'.`);
                setRedirectUrl('/');
            }
        } else {
            console.debug(`[DEBUG] No video or lobby ID was provided, redirecting to '/'.`);
            setRedirectUrl('/');
        }
        setReady(true);
    }

    useEffect(() => {
        init();
    }, [lobbyId]);

    if (!ready) {
        return <p>Loading...</p>
    }

    if (actualLobbyId) {
        return <LobbyContainer userId={ userId } lobbyId={ actualLobbyId }/>;
    } else if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    } else {
        return <Redirect to={'/'} />;
    }
}

export default LobbyResolver;
