import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, fade, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
    },
    searchField: {
        width: '75%',
        minWidth: '200px',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}))

const fullUrlMatcher = /.*youtube.com\/watch.*v=(.+)/i;

const HomePage = () => {
    const [video, setVideo] = useState('');
    const classes = useStyles(),
        history = useHistory();

    const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVideo(event.target.value);
    }

    const handleNavigateToVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
        const matches = video.match(fullUrlMatcher);
        if (matches && matches[1].length > 0) {
            history.push(`/create?v=${matches[1]}`);
        }
    }

    return (
        <div className={classes.wrapper}>
            <Paper elevation={0}>
                Create lobby
            </Paper>
            <Paper elevation={0}>
                Sign up for an account
            </Paper>
        </div>
    );
}

export default HomePage;
