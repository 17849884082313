import * as firebase from 'firebase';
import { PlayerState } from '../components/YTPlayer';
import { db } from '../../../services/firebase';
import { LobbyKeys } from '../models/lobby.model';
import { IMediaMatch } from '../../../services/media-provider.service';

export interface LobbyDTO {
    host: string;
    videoId: string | null;
    videoProvider: string | null;
    hostTimestamp: number;
    state: PlayerState;
}

export class LobbyService {
    private static lobbies: firebase.database.Reference = db.ref('/lobbies');

    public static async create(userId: string, mediaMatch?: IMediaMatch): Promise<firebase.database.Reference> {
        const newLobby: LobbyDTO = {
            [LobbyKeys.host]: userId,
            [LobbyKeys.videoUrl]: mediaMatch?.mediaId || null,
            [LobbyKeys.videoProvider]: mediaMatch?.providerId || null,
            [LobbyKeys.timestamp]: 0,
            [LobbyKeys.state]: PlayerState.unstarted
        };

        try {
            const result = await this.lobbies.push(newLobby);
            if (!result || !result.key) {
                console.error(`[ERROR] Error creating lobby.`);
                console.debug(result);
            }
            return result;
        } catch (e) {
            console.error(`[ERROR] Error in lobby creation request.`);
            console.error(e);
            return e;
        }
    }

    public static async exists(lobbyId: string): Promise<boolean> {
        const lobby = await this.lobbies.child(lobbyId).once('value');
        return lobby.val() && !!lobby.key;
    }
}
