import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Cancel, CheckCircle, Menu } from '@material-ui/icons';
import * as React from 'react';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import IconButton from '@material-ui/core/IconButton';
import useClippy from 'use-clippy';
import { useMst } from '../../../models/Root';
import { observer } from 'mobx-react';

interface LobbyStatusProps {
    playerReady: boolean;
    onSidePanelToggle: () => void;
}
const LobbyStatus = observer(({ playerReady, onSidePanelToggle }: LobbyStatusProps) => {
    const [clipboard, setClipboard] = useClippy();
    const store = useMst();
    const memberStatus = store.lobby.memberStatusCount();

    const readyToGo = memberStatus.ready === memberStatus.total;

    const getUrl = () => {
        setClipboard(window.location.href);
    }

    return (
        <AppBar position="relative" color="default">
            <Toolbar variant="dense">
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 30 }}>
                    <Typography style={{marginRight: 10 }}>Users</Typography>
                    <Typography style={{marginRight: 10 }} color={readyToGo ? 'secondary' : 'error'}>{memberStatus.ready}/{memberStatus.total || 1}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{marginRight: 10 }}>Player</Typography>
                    {
                        playerReady ? <CheckCircle color="secondary" /> :  <Cancel color="error" />
                    }
                </div>

                <div style={{ flexGrow: 1 }} />
                <div title={'Copy URL to clipboard.'}>
                    <IconButton onClick={getUrl}><FilterNoneIcon fontSize={'small'} /></IconButton>
                </div>
                {
                    readyToGo ? <Typography color="secondary">We are ready to go mai brodda.</Typography> : null
                }
                <IconButton onClick={onSidePanelToggle}>
                    <Menu />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
});

export type Color = 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';

export const TimeDisplay = ({ time, fontSize, color }: { time: number, color?: Color, fontSize?: number }) => {

    const h = Math.floor(time / 3600);
    const m = Math.floor((time % 3600) / 60);
    const s = Math.floor(time % 60);

    const paddedM = m < 10 ? `0${m}` : m,
        paddedS = s < 10 ? `0${s}` : s;

    const result = h === 0 ? `${m}:${paddedS}` : `${h}:${paddedM}:${paddedS}`;

    return (
        <Typography color={color} style={{ fontSize }}>{result}</Typography>
    );
}

export default LobbyStatus;
